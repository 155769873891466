import React, { useEffect } from "react";
import styled from "styled-components";
import { AppContainer } from "../components/common/appContainer";

const StyledContentWrapper = styled.div`
	* {
		margin: 0;
		padding: 0;
	}

	display: flex;
	justify-content: center;
	margin: 1.5rem 1rem;

	.MuiContainer-root {
		div {
			margin: 1rem 0;
		}

		ol {
			text-align: justify;
		}

		ol ol {
			margin-left: 1rem;
		}

		li,
		p {
			margin: 0.5rem 0;
		}
	}

	a {
		color: ${({ theme }) => theme.colors.brandPurple};
		text-decoration: none;
	}

	h5 {
		color: ${({ theme }) => theme.colors.brandPurple};
		font-size: 1.5rem;
		padding-bottom: 1rem;
		text-align: center;
		text-transform: uppercase;
	}
`;

const GeneralPolicyPage = ({ pageContext }) => {
	const { html } = pageContext;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<AppContainer>
			<StyledContentWrapper dangerouslySetInnerHTML={{ __html: html }} />
		</AppContainer>
	);
};

export default GeneralPolicyPage;
